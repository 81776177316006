<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-02-20 07:59:43
 * @ Description: Fixer's professional service page in the user's professional service dashboard section of the app.
 -->

<template>
    <div>
        <v-row
            justify="center"
            id="professionalServiceContainer"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-height: calc(100vh - 108px);' : 'max-height: calc(100vh - 72px);'"
            style="overflow-y: auto;"
        >
            <v-col
                cols="12"
                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : $vuetify.breakpoint.md ? 'max-width: 960px;' : $store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
            >
                <v-row>
                    <!-- Desktop Heading -->
                    <v-col
                        cols="12"
                        class="os-22-sb"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        <ul>
                            <li
                                v-for="(item, index) in serviceCategoryItems"
                                :key="index"
                                :style="item.selected ? '' : 'color: #8F9BB3;'"
                            >
                                <span
                                    :style="index > 0 ? $vuetify.breakpoint.xl ? 'padding-left: 4rem;' : 'padding-left: 2.5rem;' : ''"
                                    style="cursor: pointer; position: relative;"
                                    @click="changeCategorySelection(index, true)"
                                >
                                    {{ item.text }}
                                    <div
                                        style="background-color: #FFA858; height: 2px; width: auto;"
                                        v-if="item.selected"
                                    />
                                </span>
                            </li>
                        </ul>
                    </v-col>
                    <!-- Desktop Heading -->

                    <!-- Mobile Heading -->
                    <v-col
                        cols="12"
                        class="os-22-sb px-0"
                        v-else-if="$vuetify.breakpoint.smAndDown"
                    >
                        <v-sheet
                            class="mx-auto"
                            max-width="100%"
                            style="background-color: #F9FBFD;"
                        >
                            <v-slide-group
                                multiple
                                show-arrows
                            >
                                <v-slide-item
                                    v-for="(item, index) in serviceCategoryItems"
                                    :key="index"
                                >
                                    <div
                                        :style="item.selected ? '' : 'color: #8F9BB3;'"
                                    >
                                        <span
                                            :style="index > 0 ? $vuetify.breakpoint.xl ? 'padding-left: 4rem;' : 'padding-left: 2.5rem;' : ''"
                                            style="cursor: pointer; position: relative;"
                                            @click="changeCategorySelection(index, true)"
                                        >
                                            {{ item.text }}
                                            <div
                                                style="background-color: #FFA858; height: 2px; width: auto;"
                                                v-if="item.selected"
                                            />
                                        </span>
                                    </div>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-col>
                    <!-- Mobile Heading -->

                    <!-- Desktop Navigation -->
                    <v-col
                        cols="12"
                        class="os-13-r"
                        v-if="$vuetify.breakpoint.mdAndUp"
                    >
                        <ul>
                            <li
                                v-for="(item, index) in topNavItems"
                                :key="index"
                                :style="item.selected ? '' : 'color: #8F9BB3;'"
                            >
                                <span
                                    :style="index > 0 ? $vuetify.breakpoint.xl ? 'padding-left: 4rem;' : 'padding-left: 2.5rem;' : ''"
                                    style="cursor: pointer; position: relative;"
                                    @click="changeTopNavSelection(index, true)"
                                >
                                    {{ item.text }}
                                </span>
                                <div
                                    style="background-color: #FFA858;  height: 2px; width: 35px; position: absolute;"
                                    :style="index > 0 ? $vuetify.breakpoint.xl ? 'margin-left: 4rem;' : 'margin-left: 2.5rem;' : ''"
                                    v-if="item.selected"
                                />
                            </li>
                        </ul>
                    </v-col>
                    <!-- Desktop Navigation -->

                    <!-- Mobile Navigation -->
                    <v-col
                        v-else-if="$vuetify.breakpoint.smAndDown"
                        cols="12"
                        class="pa-0 os-13-r"
                    >
                        <v-sheet
                            class="mx-auto"
                            max-width="100%"
                            style="background-color: #F9FBFD;"
                        >
                            <v-slide-group
                                multiple
                                show-arrows
                            >
                                <v-slide-item
                                    v-for="(item, index) in topNavItems"
                                    :key="index"
                                >
                                    <v-chip
                                        class="ma-2"
                                        outlined
                                        :color="item.selected ? '#FFA858' : '#8F9BB3'"
                                        :style="item.selected ? 'color: black;' : ''"
                                        style="font-size: 15px !important;"
                                        @click="changeTopNavSelection(index, true)"
                                    >
                                        {{ item.text }}
                                    </v-chip>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-col>
                    <!-- Mobile Navigation -->
                    
                    <v-col
                        cols="12"
                        style="position: relative;"
                    >
                        <!-- View Type Toggle Buttons -->
                        <v-btn
                            style="position: absolute; right: 66px; top: -41px; min-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="list"
                            :color="selectedView === 'list' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-l-lg"
                            @click="changeSelectedView('list')"
                            v-if="$vuetify.breakpoint.mdAndUp && !this.$route.path.includes('applicant/')"
                        >
                            <v-icon
                                :color="selectedView === 'list' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiFormatListBulleted }}
                            </v-icon>
                        </v-btn>
                        <v-btn
                            style="position: absolute; right: 16px; top: -41px; in-width: 0px; max-width: 50px; opacity: 0.5;"
                            aria-label="grid"
                            :color="selectedView === 'card' ? '#2E567A' : 'white'"
                            tile
                            class="rounded-r-lg"
                            @click="changeSelectedView('card')"
                            v-if="$vuetify.breakpoint.mdAndUp && !this.$route.path.includes('applicant/')"
                        >
                            <v-icon
                                :color="selectedView === 'card' ? 'white' : '#C5CEE0'"
                            >
                                {{ mdiViewGridOutline }}
                            </v-icon>
                        </v-btn>
                        <!-- View Type Toggle Buttons -->

                        <!-- Router -->
                        <router-view
                            :selectedNavItem="selectedNavItem.text"
                            :selectedView="selectedView"
                        />
                        <!-- Router -->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mdiFormatListBulleted, mdiViewGridOutline } from '@mdi/js'

    export default {
        name: 'WorkProfessionalServicePage',

        watch: {
            '$route.path': {
                handler () {
                    if (this.$route.path.includes('/provided')) {
                        this.changeCategorySelection(0)
                    } else {
                        this.changeCategorySelection(1)
                    }

                    this.getTopNavSelection()
                }
            },

            '$vuetify.breakpoint.smAndDown': {
                handler () {
                    this.changeSelectedView('card')
                }
            }
        },

        computed: {
            topNavItems () {
                if (this.isRequested) {
                    return [
                        {
                            text: 'All Services',
                            selected: true,
                            heading: 'All Services'
                        },
                        {
                            text: 'Active Services',
                            selected: false,
                            heading: 'Active Services'
                        },
                        {
                            text: 'Pending Services',
                            selected: false,
                            heading: 'Pending Services'
                        },
                        {
                            text: 'History',
                            selected: false,
                            heading: 'History'
                        },
                        {
                            text: 'Favourites',
                            selected: false,
                            heading: 'Favourites'
                        }
                    ]
                } else {
                    return [
                        // {
                        //     text: 'All Services',
                        //     selected: true,
                        //     heading: 'All Services'
                        // },
                        {
                            text: 'Active Services',
                            selected: false,
                            heading: 'Active Services'
                        },
                        {
                            text: 'Pending Services',
                            selected: false,
                            heading: 'Pending Services'
                        },
                        {
                            text: 'History',
                            selected: false,
                            heading: 'History'
                        }
                    ]
                }
            }
        },

        data () {
            return {
                selectedNavItem: {
                    text: 'All Services',
                    selected: true,
                    heading: 'All Services'
                },
                selectedView: 'card',
                mdiFormatListBulleted,
                mdiViewGridOutline,
                isRequested: true,
                isProvided: false,
                serviceCategoryItems: [
                    {
                        text: 'Services Provided',
                        selected: true
                    },
                    {
                        text: 'Services Requested',
                        selected: false
                    }
                ],
                selectedCategoryItem: {
                    text: 'Services Provided',
                    selected: true
                }
            }
        },

        beforeMount () {
            if (this.$route.path.includes('/provided')) {
                this.changeCategorySelection(0)
            } else {
                this.changeCategorySelection(1)
            }

            this.getTopNavSelection()

            if (!this.$route.query.view) {
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            } else this.selectedView = this.$route.query.view
        },

        methods: {
            getTopNavSelection () {
                let index = null

                if (this.isRequested) {
                    switch (this.$route.path) {
                    case '/service/search':
                        index = 0
                        break;
                    case '/service/requested/active':
                        index = 1
                        break;
                    case '/service/requested/pending':
                        index = 2
                        break;
                    case '/service/requested/history':
                        index = 3
                        break;
                    case '/service/favourites':
                        index = 4
                        break;
                    }
                } else {
                    switch (this.$route.path) {
                    // case '/service/all':
                    //     index = 0
                    //     break;
                    case '/service/provided/active':
                        index = 0
                        break;
                    case '/service/provided/pending':
                        index = 1
                        break;
                    case '/service/provided/history':
                        index = 2
                        break;
                    }
                }

                this.changeTopNavSelection(index)
            },

            changeCategorySelection (newSelectedItemIndex, changeRoute) {
                const currentlySelectedItemIndex = this.serviceCategoryItems.findIndex(itemToFind => itemToFind.selected)
                if (currentlySelectedItemIndex > -1) this.serviceCategoryItems[currentlySelectedItemIndex].selected = false

                this.serviceCategoryItems[newSelectedItemIndex].selected = true
                this.selectedCategoryItem = this.serviceCategoryItems[newSelectedItemIndex]

                if (newSelectedItemIndex === 0) {
                    this.isProvided = true
                    this.isRequested = false

                    if (changeRoute) {
                        if (this.$route.path !== '/service/provided/active') this.$router.push('/service/provided/active')
                    }
                } else {
                    this.isProvided = false
                    this.isRequested = true

                    if (changeRoute) {
                        if (this.$route.path !== '/service/requested/active') this.$router.push('/service/requested/active')
                    }
                }
            },

            changeTopNavSelection (newSelectedItemIndex, changeRoute) {
                const currentlySelectedItemIndex = this.topNavItems.findIndex(itemToFind => itemToFind.selected)
                if (currentlySelectedItemIndex > -1) this.topNavItems[currentlySelectedItemIndex].selected = false

                this.topNavItems[newSelectedItemIndex].selected = true
                this.selectedNavItem = this.topNavItems[newSelectedItemIndex]

                if (changeRoute) {
                    if (this.isRequested) {
                        switch (this.selectedNavItem.text) {
                        case 'All Services':
                            if (this.$route.path !== '/service/search') this.$router.push({ path: '/service/search', query: { view: this.selectedView } })
                            break;
                        case 'Active Services':
                            if (this.$route.path !== '/service/requested/active') this.$router.push({ path: '/service/requested/active', query: { view: this.selectedView } })
                            break;
                        case 'Pending Services':
                            if (this.$route.path !== '/service/requested/pending') this.$router.push({ path:'/service/requested/pending', query: { view: this.selectedView } })
                            break;
                        case 'History':
                            if (this.$route.path !== '/service/requested/history') this.$router.push({ path: '/service/requested/history', query: { view: this.selectedView } })
                            break;
                        case 'Favourites':
                            if (this.$route.path !== '/service/favourites') this.$router.push({ path: '/service/favourites', query: { view: this.selectedView } })
                            break;
                        }
                    } else {
                        switch (this.selectedNavItem.text) {
                        case 'Active Services':
                            if (this.$route.path !== '/service/provided/active') this.$router.push({ path: '/service/provided/active', query: { view: this.selectedView } })
                            break;
                        case 'Pending Services':
                            if (this.$route.path !== '/service/provided/pending') this.$router.push({ path:'/service/provided/pending', query: { view: this.selectedView } })
                            break;
                        case 'History':
                            if (this.$route.path !== '/service/provided/history') this.$router.push({ path: '/service/provided/history', query: { view: this.selectedView } })
                            break;
                        }
                    }
                }
            },

            changeSelectedView (viewType) {
                this.selectedView = viewType

                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        view: this.selectedView
                    }
                })
            }
        }
    }
</script>
<style>
    /* @import url('@asset/style/style.fonts.css'); */

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    li {
        float: left;
    }

    li span {
        display: block;
    }
</style>
